var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"countdown-card d-flex align-center overflow-hidden",class:{
    'flex-box-center text-center': _vm.vm.mintState.showProcessEnded,
    'flex-column': !_vm.$vuetify.breakpoint.lgAndUp,
  }},[_c('div',{class:{
      'text-center': !_vm.$vuetify.breakpoint.lgAndUp || _vm.vm.mintState.showProcessEnded,
    }},[_c('div',{staticClass:"label-title"},[_vm._v(_vm._s(_vm._f("_get")(_vm.vm.mintState,'countdownText')))])]),(!_vm.vm.mintState.showProcessEnded)?_c('div',{staticClass:"d-flex",class:{
      'flex-fill justify-end': _vm.$vuetify.breakpoint.lgAndUp,
      'fill-width justify-center mt-4': !_vm.$vuetify.breakpoint.lgAndUp,
    }},[_c('div',{staticClass:"box-time"},[_c('div',{staticClass:"box-time-number"},[_vm._v(" "+_vm._s(_vm._f("twoDigits")(_vm._f("_get")(_vm.vm.mintState,'countdownDays')))+" ")]),_c('div',{staticClass:"time-type-name mt-1"},[_vm._v("Days")])]),_c('div',{staticClass:"spacing-box"},[_vm._v(":")]),_c('div',{staticClass:"box-time"},[_c('div',{staticClass:"box-time-number"},[_vm._v(" "+_vm._s(_vm._f("twoDigits")(_vm._f("_get")(_vm.vm.mintState,'countdownHours')))+" ")]),_c('div',{staticClass:"time-type-name mt-1"},[_vm._v("Hours")])]),_c('div',{staticClass:"spacing-box"},[_vm._v(":")]),_c('div',{staticClass:"box-time"},[_c('div',{staticClass:"box-time-number"},[_vm._v(" "+_vm._s(_vm._f("twoDigits")(_vm._f("_get")(_vm.vm.mintState,'countdownMinutes')))+" ")]),_c('div',{staticClass:"time-type-name mt-1"},[_vm._v("Min")])]),_c('div',{staticClass:"spacing-box"},[_vm._v(":")]),_c('div',{staticClass:"box-time"},[_c('div',{staticClass:"box-time-number"},[_vm._v(" "+_vm._s(_vm._f("twoDigits")(_vm._f("_get")(_vm.vm.mintState,'countdownSeconds')))+" ")]),_c('div',{staticClass:"time-type-name mt-1"},[_vm._v("Sec")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }